<template>
    <div>
        <section>
        <div class="relative shadow-xl rounded-3xl sm:overflow-hidden dark:bg-dark-700 bg-gray-200">
            <svg class="absolute inset-y-0 z-20 w-1/4 h-full right-1/4 dark:text-dark-700 text-gray-200" preserveaspectratio="none"
                viewbox="0 0 100 100" fill="currentcolor">
                <polygon points="0,0 100,0 0,1000" />
            </svg>
            <div class="absolute inset-y-0 z-10 w-1/2 h-full left-1/2 dark:bg-dark-800 bg-gray-300 rounded-r-3xl"></div>
            <div
                class="relative max-w-screen-xl px-4 py-16 mx-auto lg:grid lg:grid-cols-2 lg:auto-rows-max lg:gap-x-8 xl:gap-x-12 sm:px-6 sm:py-24 lg:py-32 lg:px-8" >
                <div class="flex flex-col items-center justify-center lg:items-start" >
                    <SectionTitle msg="Welcome"/>
                    <div class="z-30 w-full max-w-lg mx-auto mt-6 text-center lg:text-left lg:max-w-none md:max-w-2xl" >
                        <h1 class="text-4xl font-extrabold dark:text-white text-secondary-color sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl text-start leading-10" data-aos="zoom-in" data-aos-duration="1000" >
                            Together Information Technologies ERP Solutions
                        </h1>
                        <p class="mt-6 text-xl dark:text-dark-300 text-gray-700 text-start" data-aos="fade-right" data-aos-duration="1300">
                            Transform your business with Together Information Technologies' cutting-edge ERP solutions – streamlining operations, boosting efficiency, and propelling growth.
                        </p>
                    </div>
                    <div class="z-30 mt-10 sm:flex sm:justify-center lg:justify-start">
                        <router-link data-aos="fade-right" class="flex items-center justify-center mx-2 w-auto px-8 py-4 text-base font-semibold leading-snug transition ease-in-out bg-white rounded-full h-14 duration-250 text-dark-900 hover:text-white focus:outline-none hover:bg-gray-800"
                            to="/contact">
                            Lets get started
                        </router-link>
                        <router-link data-aos="fade-right" class="relative flex items-center justify-center w-auto px-8 mt-5 ml-0 text-base font-bold leading-snug text-gray-100 dark:text-white h-14 sm:ml-4 sm:mt-0 group"
                            to="/services">
                            <span
                                class="absolute top-0 rtl:right-0 left-0 z-10 block w-full h-full transition-all ease-in-out rounded-full bg-gradient-to-r duration-300 from-gray-600 to-gray-800 md:w-14 md:group-hover:w-full"></span>
                            <span class="relative z-20">Learn more</span>
                        </router-link>
                    </div>
                </div>
                <div  class="flex items-center justify-center max-w-xl mx-auto mt-12 sm:mt-16 lg:mt-0 lg:max-w-none">
                    <img data-aos="zoom-in" data-aos-duration="1000" src="../assets/images/erp.png"
                        class="z-30 object-cover w-auto h-full shadow-md rounded-3xl" />
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
import SectionTitle from './SectionTitle.vue'; 
export default {
    components:{
        SectionTitle,
    }
}

</script>