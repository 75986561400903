import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/axios'
import '@/assets/css/main.css'
import 'flowbite/dist/flowbite'
import 'aos/dist/aos'
import 'aos/dist/aos.css'
import AOS from 'aos'
AOS.init();
const app = createApp(App)
app.use(store)
app.use(router)
app.mount('#app')