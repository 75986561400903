<template>
    <div>
        <section class="px-4 py-10 md:py-12 bg-slate-100-800 sm:px-6 lg:px-8">
        <div
          class="max-w-xl mx-auto text-center md:max-w-2xl lg:text-left lg:max-w-screen-xl"
        >
          <SectionTitle msg="What We Do"/>
          <div class="grid w-full gap-6 mt-6 lg:grid-cols-5">
            <div class="lg:col-span-3">
              <h2
                data-aos="zoom-in" data-aos-duration="600"
                class="text-3xl font-extrabold text-gray-900 sm:text-4xl md:text-5xl"
              >
              Empower your business with Together IT's ERP Cloud Solutions.
              </h2>
            </div>
            <div class="lg:col-span-2">
              <p class="text-xl text-gray-800"
              data-aos="zoom-in" data-aos-duration="800">
                Streamline operations, enhance efficiency, and stay ahead in today's digital landscape.
              </p>
            </div>
          </div>
        </div>
        <div
          class="grid justify-center w-full gap-12 mx-auto mt-12 lg:grid-cols-2 lg:gap-8 lg:mt-16 lg:max-w-screen-xl xl:max-w-screen-xl lg:justify-start"
        >
        
        <div>
            <div v-for="service in services" 
            :key="service.id"
            :class="service.id % 2 !== 0 ? 'xl:pr-18 2xl:pr-28' : ''"
            class="w-full mt-8 lg:mt-5 xl:mt-8 2xl:mt-10 xl:pr-18">
        
            <div class="w-full"
            data-aos="fade-left" :data-aos-duration="300*service.id">
                <div class="max-w-lg mx-auto lg:mr-auto xl:mx-auto">
                <div
                    class="relative flex w-full px-8 py-6 shadow-lg rounded-3xl bg-white sm:px-10"
                >
                    <div class="text-center sm:flex sm:text-left">
                    <div class="w-full sm:w-1/5">
                        <div
                        class="flex items-center justify-center w-12 h-12 mx-auto bg-gradient-to-r rounded-2xl text-gray-100 sm:mx-0 from-gray-100 to-gray-950"
                        >
                        <div v-html="getSvgIcon(service.icon)"></div>
                        </div>
                    </div>
                    <div class="w-full mt-3 sm:mt-0">
                        <h5 class="text-lg font-semibold text-gray-900">
                        {{ service.title }}
                        </h5>
                        <p class="mt-1 text-base text-gray-700">
                        {{ service.desc }}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            </div>
        </div>
          <!-- Image collage -->
          <div
            class="flex items-center justify-center max-w-screen-md lg:max-w-full"
          >
            <div class="grid h-full grid-flow-col grid-rows-2 gap-4">
              <div class="row-span-2 shadow-xl rounded-3xl">
                <img
                  src="../assets/images/companies.jpg"
                  data-aos="zoom-in"
                  class="object-cover object-right w-full h-full rounded-3xl"
                />
              </div>
              <div class="shadow-xl rounded-3xl">
                <img
                  src="../assets/images/cloud.png"
                  data-aos="zoom-in"
                  class="object-cover object-center w-full h-full rounded-3xl"
                />
              </div>
              <div class="shadow-xl rounded-3xl">
                <img
                  src="../assets/images/web_erp.jpg"
                  data-aos="zoom-in"
                  class="object-cover object-center w-full h-full rounded-3xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
</template>

<script>
import SectionTitle from '@/components/SectionTitle.vue'
import { ref } from 'vue';
export default {
    name: 'WhatWeDo',
    components: {
        SectionTitle,
    },
    setup() {
        const services = ref([
            {
                "id" : 1,
                "title" : "Web Design",
                "desc" : "Experience the synergy of innovation and elegance with Together Information Technologies' comprehensive ERP and web design solutions – empowering your online presence and business operations.",
                "image" : "",
                "icon":"icon1",
            },
            {
                "id" : 2,
                "title" : "Web Development",
                "desc" : "Unlock your digital potential with Together Information Technologies' dynamic ERP solutions seamlessly integrated with expert web development – shaping your online landscape for success.",
                "image" : "",
                "icon":"icon2",
            },
            {
                "id" : 3,
                "title" : "Maintenance & Optimization",
                "desc" : "Elevate your digital presence with Together Information Technologies' holistic approach, combining state-of-the-art ERP solutions with meticulous maintenance and optimization services – ensuring peak performance and continual growth.",
                "image" : "",
                "icon":"icon3",
            },
        ]);
        const getSvgIcon = (icon) => {
            switch(icon) {
                case 'icon1':
                return `<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline points="7 8 3 12 7 16"/><polyline points="17 8 21 12 17 16"/><line x1="14" y1="4" x2="10" y2="20"/></svg>`;
                case 'icon2':
                return `<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6 -6a6 6 0 0 1 -8 -8l3.5 3.5"/></svg>`;
                case 'icon3':
                return `<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="8" y1="6" x2="16" y2="6"/><line x1="8" y1="12" x2="16" y2="12"/><line x1="8" y1="18" x2="16" y2="18"/></svg>`;
                default:
                return '';
            }
            };

        return {
            services,
            getSvgIcon,
        };
    },
}
</script>