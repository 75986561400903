<template>
    <div>
            <section class="px-4 pt-12 pb-10 md:pt-16 md:pb-20 sm:px-6 lg:px-8 lg:pb-64">
            <div class="max-w-screen-xl mx-auto">
            <div>
                <SectionTitle msg="Our Services"/>
                <h1
                class="mt-6 text-4xl font-extrabold text-left text-gray-700 sm:text-5xl md:text-6xl">
                We Create meaningful ERP solutions that level up your business
                </h1>
            </div>
            <div class="relative mt-6 md:mt-8 lg:mt-12 xl:mt-16">

                
                <div class="z-10 grid gap-x-8 lg:grid-cols-2 lg:absolute">
                <div>
                    <p
                    class="max-w-lg text-xl lg:mx-auto text-dark-300 sm:max-w-3xl"
                    >
                    Our team specializes in crafting ERP solutions designed to elevate your business operations to new heights. 
                    By focusing on creating meaningful and tailored solutions, we ensure that our ERP systems align perfectly with your unique business needs, streamlining processes and maximizing efficiency. 
                    Experience the power of our customized ERP solutions as they propel your business forward towards greater success and growth.
                    </p>
                    <div class="mt-8 sm:flex sm:justify-start">
                    <router-link
                        class="flex items-center justify-center w-auto px-8 py-4 text-base font-semibold leading-snug transition ease-in-out bg-gray-700 text-white rounded-full h-14 duration-250 hover:text-white focus:outline-none hover:bg-gray-900"
                        to="/contact"
                    >
                        Let's get started
                    </router-link>
                    </div>
                </div>

                <div class="grid mx-auto mt-10 md:mt-12 sm:grid-cols-2 sm:gap-x-4 lg:mt-0 sm:mx-0" >
                    <img src="../assets/images/web_erp.jpg"
                    class="object-cover object-center w-full shadow-xl rounded-3xl lg:h-96"
                    />
                    <img src="../assets/images/cloud.png"
                    class="hidden object-cover object-center w-full mt-6 shadow-xl rounded-3xl sm:block sm:mt-0 lg:h-96"
                    />
                </div>
                </div>
            </div>
            </div>
        </section>
        <section class="pt-10 pb-10 md:pb-12 md:pt-20 lg:pt-36 bg-white">
        <div class="w-full max-w-screen-xl mx-auto">
            <SectionTitle msg="بعض من منتجاتنا" class="my-9"/>
            <div v-for="(feature, index) in features" :key="index">
            <div 
            :data-aos="index %2== 0 ? 'fade-left' : 'fade-right'" 
            :data-aos-duration="500+(index*50)" 
            class="w-full px-0 md:grid md:grid-cols-2 lg:gap-x-12 xl:gap-x-36 md:gap-8 lg:px-6 my-8 text-right" >
            <div class="flex flex-col justify-center px-4 sm:px-6 md:pl-0 lg:px-0" 
            :class="index %2== 0 ? 'md:order-2' : 'md:order-1' ">
                <div>
                <p class="inline-flex items-center justify-center px-6 py-2 text-sm font-medium tracking-wide text-white rounded-r-full rounded-tl-full bg-gradient-to-r
                        dark:from-gray-600 dark:to-dark-700 from-gray-900 to-gray-300" >
                    {{ feature.category }}
                </p>
                </div>
                <h3 class="mt-4 text-3xl font-bold text-dark-800 md:mt-6 sm:text-4xl lg:text-5xl" >
                    {{ feature.title }}
                </h3>
                <p class="mt-4 text-xl md:mt-6 text-dark-300">
                    {{ feature.desc }}
                </p>
            </div>
            <div class="relative w-full mt-10 md:mt-0 md:rounded-r-3xl lg:rounded-l-3xl pt-11/12" 
            :class="index %2== 0 ? 'md:order-1' : 'md:order-2' ">
                <img :src="feature.image"
                class="absolute inset-y-0 object-cover object-center w-full h-full shadow-xl md:rounded-r-3xl lg:rounded-l-3xl"
                />
            </div>
            </div>

            </div>
        </div>
    </section>

    </div>
</template>

<script>
import SectionTitle from '@/components/SectionTitle.vue';
import { ref } from 'vue';
export default {
    components:{
        SectionTitle,
    },
    setup(){
        const features = ref([
            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"النظام الأساسي ",
                "desc":"تتمثل الوظيفة الرئيسية لهذه الموديول في الاعدادات العامة للنظام والتي تمكنك من تعريف  المنشآت وبيانات المنشآت والفروع وإعداد التنسيقات الورقية والترقيم التلقائي والوثائق والمستندات تهيئة بيانات الشركة وتهيئة الهيكل الجغرافي من الدولة والمحافظات والمدن والاحياء وتهيئة العملات واسعار الصرف وكذلك تحديد العملة التي يتعامل به النظام بم يميزها في العمل الآلي في التحويل بين العملات بحسب مأتم تعريفه في اسعار الصرف لكل العملات مقابل الريال اليمني ",
                "image":"https://sepa-cyber.com/wp-content/uploads/2021/01/Group-6083.png",
            },
            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"إدارة التحكم ",
                "desc":"تتمثل الوظيفة الرئيسية لهذه الموديول في المستخدمين والصلاحيات والمجموعات والصلاحيات وادارة الاحداث للموظف التي تتم بداخل النظام واعداد الدخول واستعراض المتواجدون حالياً وكذلك امكانية الدخول للنظام عبر تفعيل خاصية رمز التأكيد والتي يقوم النظام بأرسالها الى الايميل الداخلي او رسالة SMS وكذلك تحركات المستخدمين وتسجيل كل ما يقوم به مستخدمي النظام وتوثيق العمليات التي قاموا بها خلال عملهم على النظام بالوقت والتاريخ مثل (إضافة – تعديل – حذف -...الخ) والعمليات التي تمت على النظام وكذلك تقرير صلاحيات مستخدم وتقرير بتتبع وصول المستخدمين وكذلك عمل النسخ الاحتياطية.",
                "image":"https://www.globalsign.com/application/files/1216/2130/8053/ManagedPKI_Banner_Authentication_Blog_3_APAC_.png",
            },
            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"إدارة الحسابات العامة",
                "desc":"بناء شجرة محاسبية متناسبة مع نوع وطبيعة المنشأة وحجم نشاطها، تحديد مراكز التكلفة للمعرفة الدقيقة لكل مصروف وإيراد، إدارة النقدية والقيود المحاسبية وتقارير النظام المالي، إعداد الموازنات التقديرية ومراجعة الوثائق وإدارة الفترات المحاسبية والسنوية بالإضافة إلى الربط مع أنظمة المبيعات والمخازن والمشتريات والأصول الثابتة والموارد البشرية",
                "image":"https://smart.maitech.sd/wp-content/uploads/2023/03/%D8%A5%D8%AF%D8%A7%D8%B1%D8%A9-%D8%A7%D9%84%D8%AD%D8%B3%D8%A7%D8%A8%D8%A7%D8%AA-%D8%A7%D9%84%D9%85%D8%A7%D9%84%D9%8A%D8%A9-%D9%84%D9%84%D9%85%D8%AF%D8%A7%D8%B1%D8%B3.png",
            },
            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"إدارة المستودعات والمخازن",
                "desc":"تعريف الأصناف وترتيبها بحسب رغبة المستخدم، توفير المرونة في تسعير الأصناف وتعدد وحدات الصنف، ضمان مطابقة نتائج الجرد مع الأرصدة الفعلية، احتساب التكاليف بأي من الطرق المتعارف عليها عالمياً، وتسهيل عمليات الإدخال والإخراج من خلال المستندات المخزنية، بالإضافة إلى تقديم تقارير مستودعات متنوعة حسب الأصناف أو المجموعات أو المخازن، والربط المتكامل مع نظام المشتريات والحسابات ونظام المبيعات، مع إمكانية إضافة عدد غير محدود من المخازن والمستودعات.",
                "image":"https://media.licdn.com/dms/image/D4D12AQG7qel8VRPIsA/article-cover_image-shrink_600_2000/0/1668321258647?e=2147483647&v=beta&t=48QxHi0pmKXZxZGREpv-C5nPev9OjlP0HHQVnkHouWw",
            },

            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"إدارة المشتريات",
                "desc":"إدارة جميع خطوات عمليات الشراء بدءًا من طلب عروض الأسعار وتحديد الموردين، وتنبيه الموردين تلقائيًا من النظام، مع مقارنة الكميات المطلوبة بالكميات المستلمة والفواتير، وإدارة عمليات الشراء بالإضافة إلى إعداد تقارير شاملة ومحللة، وربط الأصناف بعناصر الحسابات التحليلية لإمكانية إعداد تقارير مفصلة، بالإضافة إلى ضبط إعدادات المشتريات وإدارة بيانات الموردين وفتح اعتمادات وتحليل المستويات، ومتابعة كافة الحركات المتعلقة بالمبيعات والموردين ودعم جميع مراحل دورة المبيعات ومعالجة المرتجعات، ومعالجة واحتساب ضريبة القيمة المضافة وإمكانية متابعة سداد الفواتير لكل مورد وإضافة قيمة المصروفات على قيمة المشتريات.",
                "image":"https://fatoorah.sa/wp-content/uploads/2023/08/%D9%82%D9%85-%D8%A8%D8%A5%D8%B5%D8%AF%D8%A7%D8%B1-%D8%A3%D8%B0%D9%88%D9%86-%D8%A7%D9%84%D8%A5%D8%B6%D8%A7%D9%81%D8%A9-%D9%88%D8%A7%D9%84%D8%B5%D8%B1%D9%81-%D9%81%D9%8A-%D9%85%D8%AE%D8%A7%D8%B2%D9%86%D9%83.webp",
            },

            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"إدارة المبيعات",
                "desc":"إدارة بيانات العملاء وتتبع مديونياتهم، ومعالجة العروض والطلبات والفواتير والمرتجعات مع متابعة تقارير البيع، بالإضافة إلى مراقبة عمليات البيع على مستوى العملاء والمندوبين والمناطق، وتتبع مبالغ التحصيل وتحديد حدود الديون لكل عميل، مع الربط المتكامل مع نظام المستودعات والحسابات، ومتابعة ورقابة كافة الحركات المتعلقة بالمبيعات والعملاء، ودعم كافة مراحل دورة المبيعات بداية من طلب العميل لعرض الأسعار وإصدار الفواتير وحتى سدادها، ومعالجة مرتجعات المبيعات واحتساب ضريبة القيمة المضافة تلقائيًا على الفواتير وربطها بالعميل والصنف، بالإضافة إلى إمكانية متابعة سداد الفواتير لكل عميل وتعريف طرق متعددة للتسعير وربطها بمجموعة من العملاء.",
                "image":"https://www.rewaatech.com/wp-content/uploads/2020/04/sales.png",
            },

            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"إدارة علاقات العملاء",
                "desc":"يساهم في بناء علاقات فردية مع العملاء وكسب ولائهم والمحافظة عليهم، ويساعد في تخفيض التكاليف وزيادة الأرباح، كما يساهم في استنباط آراء العملاء وتفضيلاتهم وتحليلها، ويسهم في زيادة المبيعات من خلال تحليل وفهم رغبات العملاء والاستجابة لها بشكل فعّال.",
                "image":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpR2OL2SAxLQx6ezHji9EWmhuKGbnr9vBHOoQ9DSg50Q&s",
            },

            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"إدارة الأصول الثابتة",
                "desc":"تعريف الأصول الثابتة وثبوت شرائها وامتلاكها، وثبوت بيع الأصول الثابتة مع النظر في الخسائر أو الأرباح المحتملة، بالإضافة إلى استعراض جميع الأصول الثابتة التي تم شراؤها خلال فترة زمنية محددة، وجرد الأصول الثابتة، واحتساب الاستهلاك، والربط مع نظام الحسابات ومراكز التكلفة لتسهيل إدارة ومراقبة الأصول بكفاءة.",
                "image":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHVzYxsiuRceTrsZgqMbRGqjN8f5HZwQlXud66RNLU7Q&s",
            },

            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"إدارة المشاريع",
                "desc":"نظام إدارة المشاريع (PMS Module) - إي أرب سلوشن هو الحل الاحترافي الكامل لإدارة المشاريع وتتبع تنفيذ وإنجاز الأعمال بكفاءة وسرعة متناهية من خلال أتمتة جميع عمليات وأنشطة ومهام كل مشروع. يعتمد النظام على معايير الدورة المستندية وتدفق العمل ووضع الخطط الزمنية (Gaant Chart) لتنفيذ كل مشروع من بدايته حتى الانتهاء من آخر مرحلة. يتيح ذلك لإدارة المنشأة مراقبة نسب الإنجاز والتكاليف والموارد المرتبطة بالمشروع، وتتبع وإدارة المهام وعمل المقارنات واتخاذ القرارات في الوقت الفعلي، مما يسهل عملية تصحيح مسارات العمل ويسرع من الإنجاز ويقلل من التكاليف.",
                "image":"https://i0.wp.com/pmcenter.bellevue.edu/wp-content/uploads/2021/11/project-management-manage.jpg?fit=800%2C617&ssl=1",
            },

            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"إدارة المستندات – الأرشفة الإلكترونية",
                "desc":"أرشفة جميع الوثائق والمستندات واسترجاعها متى ما أراد الشخص في أي وقت ومن أي مكان. تخليص المؤسسة من العبء الورقي وتقليص المصاريف وتوفير الكثير من المال والجهد والوقت المبذول في عملية الأرشفة اليدوية. مواكبة العصر وتحقيق سياسة المؤسسة التطويرية، بالإضافة إلى تخزين كافة الوثائق الخاصة بالتقويم، والتخطيط والاعتماد في مكان واحد، وتوفير امتيازات للأمن والحماية وإمكانية مشاركة المحتوى بين الزملاء وتخصيص حقول البحث والتنظيم من خلال إنشاء مجلدات رئيسية وفرعية وتقليل الحاجة إلى إرسال مرفقات في المراسلات الإلكترونية عبر البريد، وأخيرًا، يمكن مشاركة الوثائق مع الإدارات والوحدات المختلفة.",
                "image":"https://solutions-time.com/wp-content/uploads/2021/03/Document-management-system-Copy.jpg",
            },

            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"الموارد البشرية",
                "desc":"شؤون الموظفين: تسجيل الموظفين وإدخال جميع بياناتهم الشخصية، تحديد الهيكل الإداري للمنشأة، وتتبع الحركات الخاصة بالموظفين مثل النقل، التدريب، الانتداب، الترقية، وغيرها، بالإضافة إلى احتساب رصيد الإجازات ومكافأة نهاية الخدمة.الأجور والرواتب: احتساب الرواتب الشهرية، تقديم البدلات والخصومات، إدارة العلاوات والزيادات السنوية، استخراج مسيرات الرواتب، والربط مع نظام الحسابات ومراكز التكلفة.الحضور والانصراف: ربط جهاز البصمة بالنظام مباشرة لحساب التأخيرات والغياب والساعات الإضافية، القدرة على ربط النظام بأكثر من جهاز بصمة، وإمكانية استيراد وتصدير بيانات جهاز البصمة للنظام والعكس. الخدمة الذاتية: أتمتة جميع النماذج الورقية المتعلقة بالموظفين وتحويلها إلى عمل إلكتروني، إمكانية إضافة سير عمل لكل نموذج لتحويله تلقائيًا وفقًا لخطوات محددة مسبقًا. تقييمات الأداء: استعراض ومتابعة تقييمات الأداء الخاصة بالموظفين ضمن فريق العمل في نظام الخدمات الذاتية.",
                "image":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS42FcQlVYfMaZJvGTznza2UaAccLcK6iMjMky7GHfqjg&s",
            },

            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"إدارة المهام",
                "desc":"وحدة إدارة المهام Task Management Module هي إحدى وحدات نظام تخطيط موارد المؤسسات – اي أرب سلوشن، وتم تصميمها وبناءها لترتيب وتنظيم العمل المؤسسي في المنشأة داخلياً بين الموظفين أنفسهم وبين الموظفين والإدارة التنفيذية، بالإضافة إلى التواصل مع أطراف خارجية مثل العملاء، الموردين، والجهات الرسمية. يتم بناء نظام اي أرب سلوشن كــ نظام تخطيط موارد ERP على استراتيجيات التقنيات الرقمية لمواكبة التحول الرقمي العالمي، حيث يتم توفير التحول الرقمي المطلوب للعملاء بأسلوب بسيط ومفهوم يتناسب مع القيم الرقمية والأسواق المستهدفة.",
                "image":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEZy2ygXAP58VkPIHD8RHjEnnHNw94L_WcrvfSjlTLi-DzS0ORWNF1Mo-CG6dvH93UDuE&usqp=CAU",
            },

            
            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"إدارة الدعم الفني ",
                "desc":"نظام بوابة المستخدمين الرقمية هو نظام يخدم المستخدمين للنظام بشكل أوفلاين، حيث يوفر الدعم الفني والصيانة بفعالية في جميع القطاعات للجهة المعنية. تبدأ الدورة المستندية لتذاكر الدعم الفني عندما يواجه الموظف مشكلة في الخدمة أو الموارد، حيث يقوم بإرسال طلب الخدمة (تذكرة) ويوضح التفاصيل، ويتم تسجيلها في التذاكر ومتابعتها من قبل فريق خدمة الدعم حتى يتم حل المشكلة وإغلاق التذكرة.",
                "image":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDABgViiZzrcLGGUoD1ES7_gD_xmCIv60q14aZ2HiHIA&s",
            },

            
            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"نظام الصادر والوارد ",
                "desc":"إدارة الخطابات والمراسلات الصادرة والواردة وأرشفة مرفقاتها وحفظ معلوماتها الكاملة، المعاملات الداخلية، أتمتة سير العمل، الأرشفة والإرفاق، ترقيم آلي وباركود، إدارة المهام، الاعتماد والتوقيع، والبحث والتقارير.",
                "image":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRevTepoTJOax3oVfEYnvsCBjFVZ5D9UkGgMivbBefeGw&s",
            },

            
            {
                "category":"ERPالانظمة الاساسية للـ",
                "title":"إدارة الاجتماعات",
                "desc":"تحقق مؤسسات في أعظم فائدة من الاجتماعات إذا ما تمت إدارة ما تسفر عنه من قرارات ونتائج بفاعلية. يعمل النظام في منصة مداد على إدارة ",
                "image":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfxn2Fw4qO1P-_JAH2WwbiAB44eNXWsRZa8WR_RWG9kw&s",
            },            
        ])

        return {
            features,
        }
    }
}
</script>