<template>
    <div>
        <main class="bg-white">
            <!-- Hero -->
            <section class="px-4 py-12 md:py-16 sm:px-6 lg:px-8">
                <div class="max-w-screen-xl mx-auto">
                <div class="grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-16">
                    <div
                    class="flex flex-col items-start justify-between sm:items-center lg:items-start"
                    >
                    <!-- Section header -->
                    <div>
                        <div class="w-full text-left sm:text-center lg:text-left">
                            <SectionTitle msg="Contact" />
                        </div>
                        <div
                        class="w-full mt-4 text-left md:mt-5 sm:text-center lg:text-left"
                        >
                        <h1 data-aos="fade-up" data-aos-duration="500"
                            class="text-4xl font-extrabold text-gray-800 sm:text-5xl md:text-6xl"
                        >
                            Get in touch
                        </h1>
                        <p
                            data-aos="fade-up" data-aos-duration="800"
                            class="max-w-lg mx-auto mt-3 text-xl md:mt-5 text-gray-500 sm:max-w-2xl"
                        >
                            We'd love to hear from you. Fill in the form and we'll get
                            back to you shortly.
                        </p>
                        </div>
                    </div>

                    <!-- Company locations -->
                    <div
                        class="grid gap-6 mt-8 sm:mt-10 lg:mt-0 sm:grid-cols-2 sm:grid-rows-2 sm:gap-12"
                    >
                        <!-- Location 1 -->
                        <div data-aos="zoom-out" data-aos-duration="500">
                        <h5 class="text-lg font-bold text-gray-800">Sana'a, Yemen</h5>
                        <p class="mt-1 text-base text-gray-500">
                            Sana'a - asir crossroad in front of
                            <br />the Yemen Bahrain Shamil Bank ,Yemen
                        </p>
                        </div>

                        <!-- Location 2 -->
                        <div data-aos="zoom-out" data-aos-duration="750">
                        <h5 class="text-lg font-bold text-gray-800">Call Us</h5>
                        <p class="mt-1 text-base text-gray-500">
                            +967 01 47 47 47
                            <br />
                        </p>
                        </div>

                        <!-- Location 3 -->
                        <div data-aos="zoom-out" data-aos-duration="1000">
                        <h5 class="text-lg font-bold text-gray-800">Open Hours</h5>
                        <p class="mt-1 text-base text-gray-500">
                            Saturday - Wednesday
                            <br />9:00AM - 05:00PM
                        </p>
                        </div>

                        <!-- Location 4 -->
                        <div data-aos="zoom-out" data-aos-duration="1250">
                        <h5 class="text-lg font-bold text-gray-800">Email Us
                        </h5>
                        <p class="mt-1 text-base text-gray-500">
                                info@togits.com
                            <br />it.support@togits.com
                        </p>
                        </div>
                    </div>
                    <div class="w-full mt-8 sm:mt-10 lg:mt-0">
                        <h6 class="text-lg font-semibold text-left text-gray-600 sm:text-center lg:text-left" >
                            Follow us
                        </h6>
                        <div data-aos="zoom-in"
                        class="flex justify-start mt-3 space-x-4 sm:justify-center lg:justify-start" >
                        <a class="flex items-center justify-center w-12 h-12 transition duration-300 ease-in-out rounded-full bg-gradient-to-r dark:from-gray-600 dark:to-dark-700 from-gray-900 to-gray-300 text-white"
                            href="#" >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round" >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="4" y="4" width="16" height="16" rx="4" />
                            <circle cx="12" cy="12" r="3" />
                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                            </svg>
                        </a>
                        <a class="flex items-center justify-center w-12 h-12 transition duration-300 ease-in-out rounded-full bg-gradient-to-r dark:from-gray-600 dark:to-dark-700 from-gray-900 to-gray-300 text-white"
                            href="#">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6 text-white"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                                d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
                            />
                            </svg>
                        </a>
                        <a
                            class="flex items-center justify-center w-12 h-12 transition duration-300 ease-in-out rounded-full bg-gradient-to-r dark:from-gray-600 dark:to-dark-700 from-gray-900 to-gray-300 text-white"
                            href="#"
                        >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6 text-white"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                                d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z"
                            />
                            </svg>
                        </a>
                        </div>
                    </div>
                    </div>
                    <div class="w-full mt-12 lg:mt-0">
                        <iframe
                        class="w-full mt-12 rounded-2xl object-cover h-96"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d961.9205844414063!2d44.169770480990195!3d15.339489006698148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1603dd1fb8eaac13%3A0x74c571b8acafe3c6!2zVG9nZXRoZXIgY29tcGFueXwg2LTYsdmD2Kkg2KrZiNmC2KfYsNix!5e0!3m2!1sen!2s!4v1707556246263!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <!-- <div class="w-full mt-12 lg:mt-0">
                    <div class="w-full px-4 py-12 mx-auto shadow-xl rounded-3xl lg:mr-0 lg:ml-auto bg-dark-700 sm:p-16 lg:p-14 xl:p-16">
                        <form>
                        <div>
                            <label
                            for="name"
                            class="ml-0.5 text-dark-300 font-medium text-sm"
                            > Name *
                            </label>
                            <input
                            id="name"
                            type="text"
                            name="name"
                            placeholder="John Doe"
                            class="w-full p-4 mt-2 text-sm font-medium text-white transition duration-200 ease-in-out border-2 border-solid outline-none h-14 rounded-2xl bg-dark-800 border-dark-800 focus:border-dark-600 focus:outline-none"
                            required
                            />
                        </div>

                        <div class="mt-6">
                            <label
                            for="email"
                            class="ml-0.5 text-dark-300 font-medium text-sm"
                            >
                            Email *
                            </label>
                            <input
                            id="email"
                            type="email"
                            name="email"
                            placeholder="john@email.com"
                            class="w-full p-4 mt-2 text-sm font-medium text-white transition duration-200 ease-in-out border-2 border-solid outline-none h-14 rounded-2xl bg-dark-800 border-dark-800 focus:border-dark-600 focus:outline-none"
                            required
                            />
                        </div>

                        <div class="mt-6">
                            <label
                            for="phone"
                            class="ml-0.5 text-dark-300 font-medium text-sm"
                            >
                            Phone
                            </label>
                            <input
                            id="phone"
                            type="text"
                            name="phone"
                            placeholder="(123) 456-789"
                            class="w-full p-4 mt-2 text-sm font-medium text-white transition duration-200 ease-in-out border-2 border-solid outline-none h-14 rounded-2xl bg-dark-800 border-dark-800 focus:border-dark-600 focus:outline-none"
                            />
                        </div>

                        <div class="mt-6">
                            <label
                            for="message"
                            class="ml-0.5 text-dark-300 font-medium text-sm"
                            >
                            Message *
                            </label>
                            <textarea
                            id="message"
                            type="text"
                            name="message"
                            placeholder="Message"
                            rows="5"
                            class="w-full p-4 mt-2 text-sm font-medium text-white transition duration-200 ease-in-out border-2 border-solid outline-none rounded-2xl bg-dark-800 border-dark-800 focus:border-dark-600 focus:outline-none"
                            required
                            ></textarea>
                        </div>

                        <div class="flex justify-start mt-6">
                            <button
                            type="submit"
                            class="flex items-center justify-center w-auto px-8 py-4 text-base font-semibold leading-snug transition ease-in-out bg-white rounded-full h-14 duration-250 text-dark-900 hover:text-white focus:outline-none hover:bg-dark-900"
                            >
                            Send message
                            </button>
                        </div>
                        </form>
                    </div>
                    </div> -->
                </div>
                </div>
            </section>
            </main>
    </div>
</template>
<script>
import SectionTitle from '@/components/SectionTitle.vue';
export default {
    components :{
        SectionTitle,
    }
}


</script>