<template>
    <div>
        <section class="px-4 pb-10 md:pt-12 md:pb-16 sm:px-6 lg:px-8 bg-white">
            <div class="w-full max-w-screen-xl mx-auto">
            <div class="max-w-xl mx-auto md:max-w-2xl lg:max-w-none lg:mx-0 lg:grid lg:grid-cols-3 lg:gap-x-16 xl:gap-x-20 2xl:gap-x-24">
                <div class="col-span-2">
                <div class="text-center lg:text-left">
                    <SectionTitle msg="Our vision"/>
                    <h2 data-aos="zoom-in" data-aos-duration="600" class="mt-4 text-3xl font-extrabold text-gray-900 lg:mt-6 sm:text-4xl md:text-5xl">
                    We strive to achieve customer satisfaction and serve them in the best way
                    </h2>
                    <p class="mt-4 text-xl lg:mt-6 text-gray-600"
                    data-aos="zoom-in" data-aos-duration="800">
                        Ensuring customer satisfaction is at the core of our mission. We are dedicated to delivering exceptional service and exceeding our customers' expectations at every touchpoint.
                    </p>
                    <div class="flex flex-col max-w-xs mx-auto mt-8 space-y-8 sm:mt-10 sm:mx-0 sm:flex-row sm:max-w-none sm:space-x-4 lg:space-x-6 sm:space-y-0">
                    <div data-aos="zoom-in"  class="flex flex-col items-center lg:items-start">
                        <div  class="flex flex-col items-center sm:flex-row">
                        <div class="flex items-center justify-center w-12 h-12 bg-gradient-to-r rounded-2xl from-gray-300 to-gray-900">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 12l2 2l4 -4" />
                            <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3"/>
                            </svg>
                        </div>
                        <div 
                        class="mt-3 text-xl font-semibold text-gray-900 sm:mt-0 sm:ml-3">
                            Cultivate Customer Trust
                        </div>
                        </div>
                        <p class="mt-1 text-lg leading-relaxed sm:mt-3 text-gray-600" >
                            Build a strong relationship of friendship and trust with customers
                        </p>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="800" class="flex flex-col items-center lg:items-start">
                        <div class="flex flex-col items-center sm:flex-row">
                        <div class="flex items-center justify-center w-12 h-12 bg-gradient-to-r rounded-2xl from-gray-300 to-gray-900" >
                            <!-- TabbleIcon name: bulb -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7" />
                            <path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3" />
                            <line x1="9.7" y1="17" x2="14.3" y2="17" />
                            </svg>
                        </div>
                        <div class="mt-3 text-xl font-semibold text-gray-600 sm:mt-0 sm:ml-3">
                            ERP Solutions Development
                        </div>
                        </div>
                        <p class="mt-1 text-lg leading-relaxed sm:mt-3 text-dark-300" >
                            Crafting tailored ERP solutions to streamline your operations and elevate your business efficiency
                        </p>
                    </div>
                    </div>
                </div>
                </div>

                <!-- Feature image -->
                <div class="flex items-center col-span-1 mt-10 lg:mt-0">
                <div class="relative w-full text-center shadow-xl rounded-3xl pt-3/2" >
                    <img class="object-cover object-center w-full h-full align-middle rounded-3xl"
                    src="../assets/images/companies.jpg"
                    data-aos="zoom-in" />
                </div>
                </div>
            </div>
            <div class=" max-w-xl mx-auto mt-24 md:max-w-2xl lg:mt-32 lg:max-w-none lg:mx-0 lg:grid lg:grid-cols-3 lg:gap-x-16 xl:gap-x-20 2xl:gap-x-24" >
                <div class="col-span-2 lg:order-2">
                <div class="relative text-center lg:text-left w-100">
                    <SectionTitle msg="Our handbook"/>
                    <h2 class="mt-4 text-3xl font-extrabold text-gray-800 lg:mt-6 sm:text-4xl md:text-5xl"
                    data-aos="fade-up">
                    We believe in providing our clients with all financial and administrative consultations
                    </h2>
                    <p 
                    data-aos="fade-up" data-aos-duration="800"
                    class="mt-4 text-xl lg:mt-6 text-dark-300">
                        At our core, we are dedicated to offering comprehensive financial and administrative consultations to our clients. We understand the complexities and challenges businesses face in managing their finances and administrative processes. That's why we strive to provide expert guidance and support every step of the way. Our team is committed to delivering personalized solutions tailored to meet the unique needs and goals of each client. 
                    </p>
                    <div
                    class="flex flex-col max-w-xs mx-auto mt-8 sm:mt-10 sm:mx-0 sm:flex-row sm:max-w-none"
                    >
                    <div data-aos="zoom-out" data-aos-duration="800" class="flex flex-col items-center lg:flex-start lg:items-start"
                    >
                        <div class="flex flex-col items-center sm:flex-row">
                        <div
                            class="flex items-center justify-center w-12 h-12 bg-gradient-to-r rounded-2xl from-gray-300 to-gray-900"
                        >
                            <!-- TablerIcon name: user -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">

                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="12" cy="7" r="4" />
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            </svg>
                        </div>
                        <div
                            class="mt-3 text-xl font-semibold text-gray-600 sm:mt-0 sm:ml-3"
                        >
                            User focused
                        </div>
                        </div>
                        <p class="mt-1 text-lg leading-relaxed sm:mt-3 text-dark-300">
                        User-focused communication prioritizes the needs, preferences, and understanding of the audience, tailoring messages to effectively engage and resonate with them.
                        </p>
                    </div>
                    <div data-aos="zoom-out" class="flex flex-col items-center mt-8 sm:mt-0 md:flex-start lg:items-start lg:ml-6" >
                        <div class="flex flex-col items-center sm:flex-row">
                        <div class="flex items-center justify-center w-12 h-12 bg-gradient-to-r rounded-2xl from-gray-300 to-gray-900" >
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                            </svg>
                        </div>
                        <div class="mt-3 text-xl font-semibold text-gray-600 sm:mt-0 sm:ml-3" >
                            Clear Communication
                        </div>
                        </div>
                        <p class="mt-1 text-lg leading-relaxed sm:mt-3 text-dark-300" >

                            Clear communication involves conveying information in a concise, understandable manner to ensure mutual comprehension between parties.
                        </p>
                    </div>
                    </div>
                </div>
                </div>
                <div class="flex items-center col-span-1 mt-10 lg:mt-0 lg:order-1">
                <div class="relative w-full text-center shadow-xl rounded-3xl pt-3/2">
                    <img
                    data-aos="zoom-out"
                    class="object-cover object-center w-full h-full align-middle rounded-3xl"
                    src="../assets/images/web_erp.jpg"
                    />
                </div>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>

<script>
import SectionTitle from './SectionTitle.vue';
export default {
    components:{
        SectionTitle,
    }
}

</script>