<template>
  <div>
    <OurVision/>
  </div>
</template>
<script>
import OurVision from '@/components/OurVision.vue';
export default {
  components:{
    OurVision,
  }
}

</script>