<template>
    <div>
        <footer class="bg-white rounded-lg dark:bg-gray-900 m-4">
            <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                <div class="sm:flex sm:items-center sm:justify-between">
                    <router-link to="/" class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                        <img src="../assets/logos/logo.png" class="h-14" alt="Togits Logo" />
                    </router-link>
                    <ul class="flex flex-wrap items-center justify-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                        <li>
                            <router-link to="/" class="hover:underline me-4 md:me-6">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/about" class="hover:underline me-4 md:me-6">About</router-link>
                        </li>
                        <li>
                            <router-link to="/services" class="hover:underline me-4 md:me-6">Services</router-link>
                        </li>
                        <li>
                            <router-link to="/contact" class="hover:underline">Contact</router-link>
                        </li>
                    </ul>
                </div>
                <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <router-link to="/" class="hover:underline">Together Information Technology</router-link>. All Rights Reserved.</span>
            </div>
        </footer>
    </div>
</template>