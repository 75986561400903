<template>
    <div>
        <Hero />
        <WhatWeDo />
        <OurVision />
        <LetsContact />
    </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import WhatWeDo from '@/components/WhatWeDo.vue';
import OurVision from '@/components/OurVision.vue';
import LetsContact from '@/components/LetsContact.vue';
export default {
    name: 'HomeView',
    components: {
        Hero,
        WhatWeDo,
        OurVision,
        LetsContact,
    }
}
</script>
